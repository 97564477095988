<template>
  <a-modal
    :centered="true"
    :title="mdl === 0 ? '平台协议' : '服务协议'"
    :visible="visible"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <div v-html="protocol"></div>
  </a-modal>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 17:33
 * @version 1.0
 * @description 修改邮箱 弹出层
 */
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { text } from './protocolText'
export default {
  name: 'ProtocolModal',
  props: {
    mdl: {
      type: Number,
      default: 0
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      protocol: '',
    }
  },
  methods: {
    handleOk() {
      this.closeModal()
    },
  },
  watch: {
    mdl(newVal, oldVal) {
      this.protocol = newVal === 0 ? text.platform : text.service
    }
  }
}
</script>

<style scoped>

</style>
