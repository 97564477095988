<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">

      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="手机号码：">
        <a-input v-decorator="['phone', { rules: [
          { required: true, message: '请填写手机号码' },
          { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' },
          { validator: validateToNextPhone }] }]"
          placeholder="设置登录账号"/>
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback label="登录密码：">
        <a-input-password v-decorator="['password', {
          rules: [
            { required: true, message: '请输入登录密码' },
            { min: 6, max: 20, message: '1、6-20个字符，密码不能是相同的手机号码' },
            { pattern: /(?=.*[a-z])(?=.*\d)|(?=.*[#@!~%^&*.])[a-z\d#@!~%^&*.]/i, message: '2、只能包含字母、数字以及标点符号（除空格）' },
            { validator: validateToNextPassword }]
        }]" placeholder="设置你的登录密码" type="password" />
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback label="密码确认：">
        <a-input-password
          v-decorator="['confirm', { rules: [{ required: true, message: '请再次输入您的密码' }, { validator: compareToFirstPassword }] }]"
          placeholder="请再次输入你的登录密码" type="password" @blur="handleConfirmBlur" />
      </a-form-item>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="验证码" style="padding-bottom: 15px">
        <a-row :gutter="8">
          <a-col :span="12">
            <a-input v-decorator="['code', { rules: [{ required: true, message: '请填写验证码' }] }]" placeholder="请输入短信验证码"
              style="width: 100%" />
          </a-col>
          <a-col :span="7">
            <a-button :disabled="this.code.status === 1" size="small" type="primary" @click="getCode">
              {{ codeText }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>


      <!--      <div class="protocolWarp">-->
      <!--        <a-checkbox v-model="checkout" @change="changeState(checkout)"></a-checkbox>-->
      <!--        <div @click="ProtocolModal = true;ProtocolMdl = 0">《平台协议》，</div>-->
      <!--        <div @click="ProtocolModal = true;ProtocolMdl = 1">《服务协议》</div>-->
      <!--      </div>-->
      <!--      <ProtocolModal v-model="ProtocolModal" :mdl="ProtocolMdl"></ProtocolModal>-->
      <div class="protocolWarp">
        <a-checkbox v-model="checkout"></a-checkbox>
        <a href="/platform/agreement" target="_blank">《平台协议》，</a>
        <a href="/service/agreement" target="_blank">《服务协议》</a>
      </div>
      <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-button html-type="submit" size="small" style="left: 58px;" type="primary">
          下一步
        </a-button>
      </a-form-item>
    </a-form>
    <el-dialog :visible.sync="centerDialogVisible" center title="注册协议" width="50%">

      <p style="line-height: 35px;">
        【审慎阅读】<strong
          style="text-decoration: underline;">您在申请注册流程中点击同意前，应当认真阅读以下协议。请您务必审慎阅读、充分理解协议中相关条款内容，其中包括：</strong><br>

        1、<strong>与您约定免除或限制责任的条款；</strong><br>

        2、<strong>与您约定法律适用和管辖的条款；</strong><br>

        3、<strong>其他以粗体下划线标识的重要条款。</strong><br>

        如您对协议有任何疑问，可向{{ webKeyword.OPEN_SYSTEM_NAME }}服务中心咨询<br>

        <strong>【特别提示】</strong> <strong style="text-decoration: underline;">您已充分理解，{{ webKeyword.OPEN_SYSTEM_NAME
        }}是一个商业贸易采购平台，而并非面向消费者的消费购买市场，当您按照注册页面提示填写信息、阅读并同意协议且完成全部注册程序后，即表示您已充分阅读、理解并接受协议的全部内容，并将基于您的真实贸易需求而使用{{
  webKeyword.OPEN_SYSTEM_NAME }}服务。</strong>如您因{{ webKeyword.OPEN_SYSTEM_NAME }}服务与{{ webKeyword.OPEN_SYSTEM_NAME
  }}发生争议，应适用《{{ webKeyword.OPEN_SYSTEM_NAME }}服务条款》处理。<br>

        <strong style="text-decoration: underline;">阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。</strong><br>
        <a href="/platform/agreement" style="display: block;cursor: pointer;" target="_blank">《平台协议》</a>
        <a href="/service/agreement" style="display: block;cursor: pointer;" target="_blank">《服务协议》</a>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button style="background: #ff6000;outline: auto;" type="primary"
          @click="centerDialogVisible = false">同意协议</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { registerCode, checkPhone, settledAccount, settledSupplier, supplierCode } from '../../../api/Login'
import ProtocolModal from '@/components/Modal/ProtocolModal'
import { getMoney } from '@/api/finance/stream'


/**
 * @author  XuHongli
 * @date  2022/9/6 14:55
 * @version 1.0
 * @description
 */
export default {
  name: 'RegisterUserForm',
  components: { ProtocolModal },
  props: {
    isManufacturer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centerDialogVisible: false,
      code: {
        status: 0, // 0 获取验证码 1 已发送{count}秒 2 重新发送
        count: '',
        timer: null,
      },
      confirmDirty: false,
      phoneText: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: this.$form.createForm(this),
      ProtocolModal: false,
      ProtocolMdl: -1,
      checkout: false,
    }
  },
  computed: {
    ...mapGetters(['webKeyword']),
    codeText() {
      if (this.code.status === 1) {
        return `验证码已发送 ${this.code.count}秒`
      } else if (this.code.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  mounted() {

    // setTimeout(() => {
    //   this.centerDialogVisible = true
    // }, 200)
  },
  methods: {
    inputClick(e) {
      checkPhone({ phone: e.target.value }).then(res => {
        console.log(res, '手机号')
        this.phoneText = res
      }).catch(err => {
        console.log(err, '手机号err')
      })
    },
    // 获取验证码触发事件
    getCode() {
      const { form: { validateFields } } = this
      validateFields(['phone'], (err, values) => {
        if (!err) {
          // type：2   注册
          const queryData = Object.assign(values, { type: 2 })
          const times = 60 // 倒计时时间
          if (!this.timer) {
            if (this.isManufacturer) { // 为true是调用供应商注册验证码
              supplierCode(queryData.phone).then((res) => {
                this.$message.success('发送验证码成功')
              }).catch(() => {
                this.code.status = 2
                clearInterval(this.code.timer)
                this.code.timer = null
              })
            } else { // 反之是调用采购商注册验证码
              registerCode(queryData.phone).then((res) => {
                this.$message.success('发送验证码成功')
              }).catch(() => {
                this.code.status = 2
                clearInterval(this.code.timer)
                this.code.timer = null
              })
            }

            this.code.count = times
            this.code.status = 1
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= times) {
                this.code.count--
              } else {
                this.code.status = 2
                clearInterval(this.code.timer)
                this.code.timer = null
              }
            }, 1000)
          }
        }
      })
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    // 账号
    validateToNextPhone(rule, value, callback) {
      checkPhone({ phone: value }).then(res => {
        if ('手机号已注册' === res) {
          callback(res)
        } else {
          callback()
        }
      }).catch(err => {
        console.log(err, '手机号err')
      })
    },
    // 密码
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    // 确认密码
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback('两次输入的密码必须相同')
      } else {
        callback()
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (!this.checkout) {
        this.$message.info('请勾选同意平台服务协议')
        return
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          const phoneParam = {
            values
          }
          if (this.isManufacturer) {
            /* 供应商 */
            settledSupplier(phoneParam.values).then((res) => {
              let { data, msg } = res
              if ('infoNotOk' !== msg) phoneParam['id'] = data
              this.$emit('ok', phoneParam)
            }).catch((err) => {
              console.log('错误信息', err)
            })
          } else {
            /* 采购商 */
            settledAccount(phoneParam.values).then((res) => {
              let { data, msg } = res
              if ('infoNotOk' !== msg) phoneParam['id'] = data
              this.$emit('ok', phoneParam)
            }).catch((err) => {
              console.log('错误信息', err)
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.protocolWarp {
  width: 813px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10px 0 20px;
  color: #1697f9;
  cursor: pointer;
  font-size: 12px;
}

.protocolWarp>a {
  display: block;
}

.ant-row>>>.ant-form-explain {
  text-align: left;
}
</style>
