<template>
  <div class="registerSteps">
    <a-steps v-if="steps" :current="current">
      <a-step v-for="(item, index) in steps" :key="item.title" :title="item.title" @click="nextStep(index)" />
    </a-steps>
    <h3 v-if="steps" class="my-10">{{ steps[current].title }}</h3>
    <div v-if="current < 2" class="info_ys">
      <span v-if="current === 0">有企业营业执照（含个体工商户）的用户请注册。权益如下：做企业实名认证；作为 <span class="span_text">{{ isManufacturer ? '
                卖家身份供货。' : ' 买家身份采购。' }} </span></span>
      <span v-else>您的用户账户已经注册成功，请尽快填写以下企业资料提交认证审核，审核通过后才能正常登录</span>
    </div>
    <div class="steps-content">
      <RegisterUserForm v-if="current == 0" :isManufacturer="isManufacturer" @ok="userFormFinish"></RegisterUserForm>
      <RegisterEnterpriseForm v-if="current == 1" :isManufacturer="isManufacturer" @ok="registerEnterpriseFormFinish"
        @oks="objUserInfo" @prev="prev"></RegisterEnterpriseForm>
      <div v-if="current == 2" class="flex-column flex-row-center flex-col-center">
        <a-result :title="isManufacturer ? '您已成功提交供应商入驻信息!' : '您已成功提交采购商注册信息!'" status="success"
          sub-title="信息审核需要1-5个工作日, 请您耐心等待">
          <template #extra>
            <a-button key="console" type="primary" @click="log">
              返回登录
            </a-button>
            <a-button key="buy" @click="$router.push('/')">
              返回首页
            </a-button>
          </template>
        </a-result>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterUserForm from '@/views/Login/Form/RegisterUserForm'
import RegisterEnterpriseForm from '@/views/Login/Form/RegisterEnterpriseForm'
import { submitUserRegisterFrom, submitManufacturerRegister } from '@/api/Login'


export default {
  name: 'RegisterSteps',
  components: { RegisterEnterpriseForm, RegisterUserForm },
  created() {
    this.$store.state.bottom = false
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.state.bottom = true
      }, 500)
    })

    if (this.$route.query.id) {
      console.log('判断审核流程id', this.$route.query)
      this.current = +this.$route.query.id == 3 ? 2 : +this.$route.query.id || 0
    }

    // 切换供应商入驻 默认采购入驻
    if (this.$route.query.type === 'Manufacturer') {
      this.isManufacturer = true
    }
    if (Object.keys(this.$store.getters.userInfo).length > 0 && this.$store.getters.infoProfect === 0) {
      this.current = 1
    }
  },
  mounted() {

  },
  data() {
    return {
      userForms: {},
      userForm: {},
      registerEnterpriseForm: {},
      current: 0,
      isManufacturer: false,
      steps: [
        {
          title: '用户名密码',
          content: 'First-content',
        },
        {
          title: '填写企业资料',
          content: 'Second-content',
        },
        {
          title: '完成',
          content: 'Last-content',
        },
      ],
    }
  },
  methods: {
    prev() {
      this.current--
    },
    log() {
      let jump = ''
      if (this.isManufacturer) {  // 供应商
        if (process.env.NODE_ENV === 'production') {
          jump = `https://sm.wanjingsc.com/#/login`
        } else if (process.env.NODE_ENV === 'pre') {
          jump = `http://smpre.aijdan.com/#/login`
        } else {
          jump = `http://smpre.aijdan.com/#/login`
        }
        window.open(jump, '_black')
      } else {  // 采购商
        this.$store.dispatch('FedLogOut').then(() => {
          if (this.$tp().IS_OPEN == 1) {
            let PAGE_URL = window.origin + '/user/login'
            window.open(PAGE_URL, '_blank')
          } else {
            this.$router.push({ path: '/login' })
          }
        })
      }
    },
    objUserInfo(val) {
      if (this.isManufacturer) {
        this.userForms = {
          userPhone: val.userPhone
        }
      } else {
        this.userForms = {
          phone: val.phone
        }
      }

    },
    registerEnterpriseFormFinish(val) {
      console.log(val, '审核')
      this.registerEnterpriseForm = val
      // 区分类型 入住
      if (this.isManufacturer) {
        console.log(this.registerEnterpriseForm, '审核this.registerEnterpriseForm')
        let jies
        if (this.$route.query.infoProfect !== undefined) {
          jies = JSON.parse(this.$route.query.infoProfect)
          if (0 == jies.infoProfects) {
            this.userForm['userPhone'] = jies.userPhones
          }
        }
        if (jies !== undefined && (3 == jies.infoProfects || 0 == jies.infoProfects)) {
          this.userForms['userPhone'] = this.userForms.userPhone
        } else if (0 == this.$store.getters.infoProfect) {
          this.userForm['userPhone'] = this.userForm.values.phone
        } else if (1 == this.$store.getters.infoProfect) {
          this.userForm['userPhone'] = this.userForm.values.phone
        }

        console.log(this.$store.getters, 'this.$store.getters')
        console.log(this.userForms, '供应商this.userForms')
        console.log(this.userForm, '供应商this.userForm')

        let obj
        if (val.isBooleen) {
          obj = {
            requestId: this.registerEnterpriseForm.requestId,
            userPhone: 3 == this.$store.getters.infoProfect ? this.userForms.userPhone : this.userForm.userPhone,
            'businessName': val.companyName || null,
            'organizationCode': val.licenseNo || null,
            'legalPerson': val.legalRepresentative || null,
            'companyAddress': val.registerAddress || null,
          }
        } else {
          obj = {
            requestId: this.registerEnterpriseForm.requestId,
            userPhone: 3 == this.$store.getters.infoProfect ? this.userForms.userPhone : this.userForm.userPhone
          }
        }
        console.log(obj, 'obj')
        submitManufacturerRegister(Object.assign(obj)).then((res) => {
          this.current++
        })
      } else {

        console.log(this.$store.getters.infoProfect, 'this.$store.getters.infoProfect')
        console.log(this.userForm, '采购商this.userForm')
        console.log(this.userForms, '采购商this.userForms')

        if (this.$store.getters.infoProfect !== 3) {
          this.userForm['phone'] = this.userForm.values.phone
        } else if (this.$store.getters.infoProfect == 3) {
          this.userForm['phone'] = this.userForms.phone
        }

        let obj
        if (val.isBooleen) {
          obj = {
            requestId: this.registerEnterpriseForm.requestId,
            phone: this.userForm.phone,
            'businessName': val.companyName || null,
            'organizationCode': val.licenseNo || null,
            'legalPerson': val.legalRepresentative || null,
            'companyAddress': val.registerAddress || null,
          }
        } else {
          obj = {
            requestId: this.registerEnterpriseForm.requestId,
            phone: this.userForm.phone
          }
        }
        console.log(obj, 'obj')

        submitUserRegisterFrom(Object.assign(obj)).then((res) => {
          this.current++
        })

      }
    },
    userFormFinish(val) {
      this.current++
      this.userForm = val
    },
    next() {
      this.current++
    },
    nextStep(index) {
      if (index !== 0) return
      this.current = index
    }
  },
}
</script>

<style lang="scss" scoped>
.info_ys {
  text-align: center;
  margin-top: 25px;
  font-weight: 600;
  font-size: 14px;

  .span_text {
    color: red;
    font-size: 16px;
  }
}

.registerSteps .ant-steps-item-process ::v-deep .ant-steps-item-icon>.ant-steps-icon {
  color: #FFFFFF;
}

.registerSteps {
  width: 1100px;
  padding-top: 40px;
  margin: 0 auto;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 40px;
}

.steps-action {
  margin-top: 24px;
}
</style>
