<template>
  <div>
    <div style="color:red;font-size: 16px;margin-bottom: 15px;font-weight: bold;">
      {{ createForm==true?'您的审核未通过，请重新提交资料！':'' }}
    </div>
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="企业证件类型："
      >
<!--        readOnly(applyDetail.length>0?!applyDetail.includes('credentialsType'):false)-->
        <a-input
          v-decorator="['credentialsType', { rules: [{required: true, message: '请选择企业证件类型'}] }]"
          disabled
          style="width: 100%"/>
<!--        <a-select-->
<!--          v-decorator="['credentialsType', { rules: [{required: true, message: '请选择证件类型'}] }]"-->
<!--          :disabled="applyDetail.length>0?!applyDetail.includes('credentialsType'):false"-->
<!--          placeholder="证件类型">-->
<!--          <a-select-option value="营业执照">营业执照</a-select-option>-->
<!--        </a-select>-->
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="营业执照："
      >
        <UploadImg v-decorator="['credentialsPic', { rules: [{required: true, message: '请上传大小不超过2M的图片，支持JPG/PNG格式' }] }]" @ok="onUploadImg" >
          <div slot-scope="{ data }">
            <img v-if="data.value" :src="data.value" class="img-solt">
            <img v-else alt="" class="img-solt" src="@/assets/images/businessLicence.png">
          </div>
        </UploadImg>
        <span v-if="checkException" class="hint-warning">{{ checkException }}</span>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="企业名称："
      >
        <a-input
          v-decorator="['companyName', { rules: [ { required: true, message: '请填写企业名称' } ] }]"
          :disabled="applyDetail.length>0?!applyDetail.includes(isBooleen ? 'businessName' : 'companyName'):false"
          :readOnly="isBooleen ? false : isReadOnly"
          placeholder="请输入营业执照上的公司名称"
          style="width: 100%"/>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="统一社会信用代码"
      >
        <a-input
          v-decorator="['licenseNo',
          {rules: [ { required: true, message: '请填写统一社会信用代码/组织机构代码' },{ pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/, message: '请填写正确的统一社会信用代码/组织机构代码' }]}]"
          :disabled="applyDetail.length>0?!applyDetail.includes(isBooleen ? 'organizationCode' : 'licenseNo') : false"
          :readOnly="isBooleen ? false : isReadOnly"
          placeholder="请输入营业执照上的18位信用代码"
          style="width: 100%"/>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="法人姓名："
      >
        <a-input
          v-decorator="['legalRepresentative',
          {rules: [{ required: true, message: '请填写法定代表人' }]}]"
          :disabled="applyDetail.length>0?!applyDetail.includes(isBooleen ? 'legalPerson' : 'legalRepresentative') : false"
          :readOnly="isBooleen ? false : isReadOnly"
          placeholder="请输入营业执照上的法定代表人" />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="注册地址：">
        <a-input
          v-decorator="['registerAddress',
          {rules: [{required: true, message: '请选择州/省/地区'}]}]"
          :disabled="applyDetail.length>0?!applyDetail.includes(isBooleen ? 'companyAddress' : 'registerAddress') : false"
          :readOnly="isBooleen ? false : isReadOnly"
          placeholder="请输入公司的营业地址" />
<!--        <AreaSelect-->
<!--          v-decorator="['companyAddress', {rules: [{required: true, message: '请选择州/省/地区'}]}]"-->
<!--          :applyDetail="applyDetail"-->
<!--          placeholder="请输入公司的营业地址"></AreaSelect>-->
      </a-form-item>
<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="详细地址："-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="['companyLocation',{rules: [-->
<!--            {required: true, message: '请填写单位详细地址'},-->
<!--            {required: true,min:4,max:120, message: '请输入长度在4-120个字符之间，且不包含特殊字符'},-->
<!--            {pattern: /^[\u4e00-\u9fa50-9A-Za-z,-@#+=!（）()]*$/, message: '输入的格式不正确' }]}]"-->
<!--          :disabled="applyDetail.length>0?!applyDetail.includes('companyLocation'):false"-->
<!--          placeholder="请输入公司的详细地址"/>-->
<!--      </a-form-item>-->
<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="电子邮箱："-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="[isManufacturer ? 'userEmail' : 'email',{ rules: [{required: true, message: '请输入邮箱地址'},{ pattern: /^([a-zA-Z\d_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z\d_-])+/, message: '请输入正确的电子邮箱' }]}]"-->
<!--          :disabled="applyDetail.length>0?!applyDetail.includes(isManufacturer ? 'userEmail' : 'email'):false"-->
<!--          placeholder="请输入电子邮箱"/>-->
<!--      </a-form-item>-->
      <a-form-item
        :wrapperCol="{ span: 24 }"
        style="text-align: center"
      >
        <a-button htmlType="submit" type="primary">同意协议并申请</a-button>
      </a-form-item>
      <div class="protocolWarp">
        <a-checkbox v-model="checkout"></a-checkbox>
        <a href="/platform/agreement" style="display: block;cursor: pointer;" target="_blank">《平台协议》</a>
        <a href="/service/agreement" style="display: block;cursor: pointer;" target="_blank">《服务协议》</a>
      </div>
      <ProtocolModal v-model="ProtocolModal" :mdl="ProtocolMdl"></ProtocolModal>
    </a-form>
  </div>

</template>

<script>
import AreaSelect from '@/components/AreaSelect'
import UploadImg from '@/components/UpLoadImg/UploadImg'
import ProtocolModal from '@/components/Modal/ProtocolModal'
import {  verifyUser, verifySupplier, getPirchBusiness, getManufacBusiness } from '@/api/Login'
import pick from 'lodash.pick'

const fields = ['credentialsType', 'credentialsPic', 'companyName', 'licenseNo', 'legalRepresentative', 'registerAddress']

export default {
  name: 'RegisterEnterpriseForm',
  components: { ProtocolModal, UploadImg, AreaSelect },
  props: {
    isManufacturer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkException: null,
      requestId: '',
      isReadOnly: false,
      ProtocolModal: false,
      ProtocolMdl: -1,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: this.$form.createForm(this),
      checkout: false,
      createForm:false,
      applyDetail:[],
      isBooleen: false // 判断是否在供应商不通过审核
    }
  },
  computed: {
    codeText() {
      if (this.code.status === 1) {
        return `验证码已发送 ${this.code.count}秒`
      } else if (this.code.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  destroyed() {
    console.log('销毁', this.isReadOnly)
  },
  created(){
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 企业证件默认营业执照
    this.form.setFieldsValue({
      credentialsType: `营业执照`
    })

    // 当 model 发生改变时，为表单设置值
    console.log(this.$store.getters.infoProfect, '账号状态')

    if (this.isManufacturer && 3 == this.$route.query.infoProfect) {  // 供应商审核不通过调接口
      if (!this.$store.getters.access_token) {
        this.$store.commit('SET_ACCESS_TOKEN', this.$route.query.token)
      }
      verifySupplier().then(res => {
        console.log('verifySupplier供应商', res)
        this.$emit('oks', res.data)
        this.form['userPhone'] = res.data.userPhone
        // res.data.companyAddress = res.data.companyAddress.split(' ')
        this.form.setFieldsValue(pick(res.data, fields))
        this.form.setFieldsValue({
          'companyName': res.data.businessName,
          'legalRepresentative': res.data.legalPerson,
          'licenseNo': res.data.organizationCode,
          'registerAddress': res.data.companyAddress
        })
        this.applyDetail = res.data.applyDetail ? res.data.applyDetail.split(','):[]
        if (this.applyDetail && this.applyDetail.length) {
          this.isBooleen = true
          this.isReadOnly = false
        }
        if(res.data === null){
          this.createForm = false
        } else {
          this.createForm = true
        }
      })
    }
    if(this.$route.query.id == 1 && this.$store.getters.infoProfect !== 0 && !this.isManufacturer) {
      verifyUser().then(res => {
        console.log('verifyUser采购商', res)
        // res.data.companyAddress = res.data.companyAddress.split(' ')
        this.$emit('oks', res.data)
        this.form.setFieldsValue(pick(res.data, fields))
        this.form.setFieldsValue({
          'companyName': res.data.businessName,
          'legalRepresentative': res.data.legalPerson,
          'licenseNo': res.data.organizationCode,
          'registerAddress': res.data.companyAddress
        })
        this.applyDetail = res.data.applyDetail ? res.data.applyDetail.split(','):[]
        if (this.applyDetail && this.applyDetail.length) {
          this.isBooleen = true
          this.isReadOnly = false
        }
        if(res.data === null){
          this.createForm = false
        } else {
          this.createForm = true
        }
      })
    } else {
      this.createForm = false
    }
  },
  methods: {
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue(this.isManufacturer ? 'userPassword' : 'passWord')) {
        callback('两次输入的密码必须相同')
      } else {
        callback()
      }
    },

    onUploadImg(val) {

      if (val && this.isManufacturer) {
        getManufacBusiness({fileUrl: val}).then(res => {
          if (res.ok) {
            console.log('供应商', res)
            this.checkException = typeof res.data == 'string' ? res.data : null
            let { companyName, licenseNo, legalRepresentative, registerAddress } = res.data
            let obj = {
              companyName,
              licenseNo,
              legalRepresentative,
              registerAddress,
            }
            this.requestId = res.data.id
            this.form.setFieldsValue(pick(obj, fields))
            this.isReadOnly = true
          }
        })
      } else {
        getPirchBusiness({fileUrl: val}).then(res => {
          if (res.ok) {
            console.log('采购商', res)
            this.checkException = typeof res.data == 'string' ? res.data : null
            let { companyName, licenseNo, legalRepresentative, registerAddress } = res.data
            let obj = {
              companyName,
              licenseNo,
              legalRepresentative,
              registerAddress,
            }
            this.requestId = res.data.id
            this.form.setFieldsValue(pick(obj, fields))
            this.isReadOnly = true
          }
        })
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (!this.checkout) {
        this.$message.info('请勾选同意平台服务协议')
        return
      }
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // values.companyAddress = values.companyAddress.join(' ')
          if (this.isBooleen) {
            values['isBooleen'] = this.isBooleen
          }
          values['requestId'] = this.requestId
          console.log('Received values of form: ', values)
          this.$emit('ok', values)
        }
      })
    },

  },
}
</script>

<style scoped>
.hint-warning {
  color: #f5222d;
}
.protocolWarp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10px 0 20px;
  color: #1697f9;
  cursor: pointer;
  font-size: 12px;
}

#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

.img-solt {
  width: 269px;
  height: 100%;
}
</style>
